<template>
  <div class="w-full">
    <SuccessButton
      class=""
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalCadastroPerfil = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar perfil"
      @hide="modalCadastroPerfil = false"
      :active="modalCadastroPerfil"
      @success="submitForm()"
      @excluir="excluirPerfil()"
      @status="mudarStatusPerfil()"
      size="small"
    >
      <div slot="body">
        <form>
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input
                class="w-full mb-4 mt-5"
                placeholder="Nome"
                v-model="perfil.nome"
                name="nome"
                v-validate="'required'"
              />
              <span class="text-danger text-sm" v-show="errors.has('nome')">{{
                $validators.empty
              }}</span>
            </div>
          </div>
        </form>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      perfil: {},
      modalCadastroPerfil: false,
      error: false
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarPerfil();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarPerfil() {
      this.$vs.loading();
      try {
        const res = await this.$http.post("perfil", this.perfil);
        this.perfil = {};
        this.$vs.notify(this.$notify.Success);
        this.modalCadastroPerfil = false;
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do perfil N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.$vs.loading.close();
      }
    }
  },
  components: components
};
</script>

<style></style>
