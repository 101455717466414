<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-w="12">
      <!-- SELECAO DE ZONA -->
      <vs-col vs-w="3" class="px-2">
        <div class="vs-con-loading__container">
          <vx-card card-background="#021a33">
            <vs-row vs-type="flex">
              <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <div class="p-1">
                  <h4 style="color: white">Perfis</h4>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-w="9" vs-justify="flex-end" vs-align="center">
                <ModalCadastroPerfil @update="getPerfil()" />
              </vs-col>
            </vs-row>
          </vx-card>
          <vs-list v-for="(perfil, idx) in perfis" v-bind:key="idx">
            <vx-card
              :class="
                perfilAtual != perfil.id ? 'list-item' : 'active-list-item'
              "
              @click="
                perfilAtual = perfil.id;
                getAtividadeByPerfil(perfil.id);
              "
            >
              <template slot="no-body">
                <div>
                  <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-col
                      class="py-2 px-2"
                      vs-w="10"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <vs-list-item :title="perfil.nome"></vs-list-item>
                    </vs-col>
                    <vs-col
                      class="py-1 px-2"
                      vs-w="2"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                    >
                      <ModalEditarPerfil @update="getPerfil()" class="right" :perfil="perfil" />
                    </vs-col>
                  </vs-row>
                </div>
              </template>
            </vx-card>
          </vs-list>
        </div>
      </vs-col>
      <vs-col vs-w="9">
        <div class="vs-con-loading__container">
          <vx-card card-background="#000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="9"
                class="mt-1"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <div>
                  <h4 style="color: white">Atividades</h4>
                </div>
              </vs-col>
              <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end">
                <template>
                  <vs-button
                    size="small"
                    color="success"
                    type="relief"
                    :disabled="!$atividades.permissoes.includes(14) || perfilAtual == ''"
                    @click="addAtividades()"
                  >Atualizar</vs-button>
                </template>
              </vs-col>
            </vs-row>
          </vx-card>
        </div>
        <vx-card>
          <div id="atividade_card" class="w-full mt-2">
            <vs-row vs-w="12" vs-type="flex" vs-justify="space-between" class="w-full">
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                v-for="(tr, index) in atividades"
                :key="index"
              >
                <div class="vx-row mb-1">
                  <div class="vx-col">
                    <vs-switch
                      color="success"
                      v-model="atividadesSelecionadas[index]"
                      @click="addToPerfil(tr.id, index)"
                    />
                  </div>
                  <div class="vx-col">
                    <h5>{{ tr.nome }}</h5>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import components from "@/components/default/exports.js";
import ModalCadastroPerfil from "./cadastro.vue";
import ModalEditarPerfil from "./editar.vue";

export default {
  data() {
    return {
      perfilAtual: "",
      perfis: [],
      atividades: [],
      atividadesSelecionadas: []
    };
  },
  mounted() {
    this.getPerfil();
    this.getAtividades();
  },
  methods: {
    async addAtividades() {
      await this.$vs.loading({ container: "#atividade_card", scale: 0.6 });
      const atividades = [];
      for (let i = 0; i < this.atividades.length; i++) {
        if (this.atividadesSelecionadas[i]) {
          atividades.push(this.atividades[i].id);
        }
      }

      try {
        await this.$http.post("perfil_atividade", {
          id: this.perfilAtual,
          atividades: atividades
        });

        if (this.perfilAtual == undefined || this.perfilAtual == null) {
          this.getAtividades();
        } else {
          this.getAtividadeByPerfilSelected(this.perfilAtual);
        }
        await this.getPerfil();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#atividade_card > .con-vs-loading");
      }
    },
    async getPerfil() {
      this.$vs.loading();
      try {
        this.perfis = await this.$http.get("perfil");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async getAtividades() {
      this.atividades = await this.$http.get("atividade");
      this.atividadesSelecionadas = [];
      for (let i = 0; i < this.atividades.length; i++) {
        this.atividadesSelecionadas.push(false);
      }
    },
    async getAtividadeByPerfil(perfil) {
      await this.$vs.loading({
        container: "#atividade_card",
        scale: 0.6
      });
      this.atividadesSelecionadas = this.atividadesSelecionadas.map(
        () => false
      );
      try {
        const res = await this.$http.get(`perfil_atividade/${perfil}`);
        const atividades = await this.atividadesSelecionadas;
        for (let i = 0; i < res.length; i++) {
          const index = this.atividades.map(x => x.id).indexOf(res[i].id);
          atividades[index] = true;
        }
        await this.$set(this.atividadesSelecionadas, atividades);
        await this.$vs.loading.close("#atividade_card > .con-vs-loading");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close("#atividade_card > .con-vs-loading");
      }
    },
    async getAtividadeByPerfilSelected(event) {
      this.atividadesSelecionadas = this.atividadesSelecionadas.map(
        () => false
      );
      try {
        const res = await this.$http.get(`perfil_atividade/${event}`);
        const atividades = this.atividadesSelecionadas;
        for (let i = 0; i < res.length; i++) {
          const index = this.atividades.map(x => x.id).indexOf(res[i].id);
          atividades[index] = true;
        }
        this.$set(this.atividadesSelecionadas, atividades);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async addToPerfil(idAtividade, index) {
      const atividades = this.atividadesSelecionadas;
      let have = atividades[index];
      have = have == false ? true : false;
      atividades[index] = have;
      this.atividadesSelecionadas = atividades;
    }
  },
  components: {
    draggable,
    ...components,
    ModalCadastroPerfil,
    ModalEditarPerfil
  }
};
</script>
<style>
.vs-list--subtitle {
  font-size: 13px !important;
}
.list-item {
  color: black;
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.list-item:hover {
  color: black;
  transition: 0.1s;
  background: #eeeeee;
  transform: translateY(-3px);
}
.active-list-item {
  color: black;
  background-color: #00bba2;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}

.right {
  margin-left: auto;
  margin-right: 0;
}
.scroll-area-pros {
  height: 70vh !important;
}
</style>
