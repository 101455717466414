<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarPerfil = true"
    ></EditButton>
    <SideBar
      title="Editar perfil"
      @hide="cancelar"
      @excluir="excluirPerfil"
      @status="mudarStatusPerfil"
      :active="modalEditarPerfil"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="perfil"
      size="small"
      textSucces="Atualizar"
      @success="submitForm()"
    >
      <div slot="body" class="my-2">
        <vs-row vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <div class="w-full">
              <vs-input
                class="inputx w-full"
                label-placeholder="Nome"
                v-model="perfil.nome"
                name="nome"
                v-validate="'required'"
              />
              <vs-row>
                <span class="text-danger text-sm" v-show="errors.has('nome')">
                  {{ $validators.empty }}
                </span>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarPerfil",
  props: { perfil: Object },
  data() {
    return {
      modalEditarPerfil: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.perfil);
    this.originalData = this.perfil;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarPerfil();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarPerfil() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(
          `perfil/${this.perfil.id}`,
          this.perfil
        );
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Edição do perfil N°" + res.id
        // };
        // await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarPerfil = false;
      } catch (err) {
        console.log(err)
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async excluirPerfil() {
      this.$vs.loading();
      try {
        await this.$http.delete(`perfil/${this.perfil.id}`);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão do perfil N°" + this.perfil.id
        // };
        // await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusPerfil() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`perfil/${this.perfil.id}`, {
          lixeira: !this.perfil.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status do perfil N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.perfil, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarPerfil = false;
    }
  },
  components: components
};
</script>

<style></style>
